/**
 * This file overrides the themes according to TT themes
 */

import { createTheme } from '@mui/material/styles';

const TT_THEME = createTheme({
  palette: {
    primary: {
      main: '#1B9827',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.26)',
    },
    error: {
      main: '#F44336',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Roboto',
    },
    h1: { fontSize: '6rem' },
    h2: { fontSize: '3.75rem' },
    h3: { fontSize: '3rem' },
    h4: { fontSize: '2.12rem' },
    h5: { fontSize: '1.5rem' },
    h6: { fontSize: '1.25rem' },
    subtitle1: { fontSize: '1rem' },
    subtitle2: { fontSize: '0.875rem' },
    body1: { fontSize: '1rem' },
    body2: { fontSize: '0.875rem' },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginBottom: 8,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          boxShadow: '0px 0px 0px 1px #E0E0E0',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontFamily: 'Roboto',
          cursor: 'pointer',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          boxShadow: '0px 0px 0px 1px #E0E0E0',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(63,181,68,0.08)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              '&:hover': {
                backgroundColor: '#3F51B5',
              },
            }),
        }),
      },
    },
  },
});

export default TT_THEME;
